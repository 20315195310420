<template>
  <div class="d-flex flex-column flex-fill white pb-5">
    <h1 class="text-center">Recibo de Reforço de Caixa</h1>
    <h4 class="text-center my-5">Fisiopower</h4>
    <div class="d-flex justify-center my-5">
      <table>
        <tr>
          <th>Data e Hora</th>
          <td>{{ rf.date | moment("DD/MM/YYYY HH:mm:ss") }}</td>
        </tr>
        <tr>
          <th>Nº Caixa</th>
          <td>{{ rf.id_box }}</td>
        </tr>
        <tr>
          <th>Valor do Refor.</th>
          <td>R$ {{ rf.value | money }}</td>
        </tr>
        <tr>
          <th colspan="2">Descrição</th>
        </tr>
        <tr>
          <td class="description" colspan="2">
            {{ rf.description }}
          </td>
        </tr>
        <tr>
          <th>Vendedor</th>
          <td>{{ rf.seller.first_name }}</td>
        </tr>
      </table>
    </div>
    <div class="signature mt-15">
      <span>{{ rf.seller.first_name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reinforce",
  data() {
    return {
      rf: null,
    };
  },
  beforeMount() {
    this.rf = this.rfSession;
    if (!this.rfSession) this.$router.push({ name: "ReinforceBox" });
  },
  computed: {
    rfSession() {
      const rf = sessionStorage.getItem("transfer");
      sessionStorage.removeItem("transfer");
      return JSON.parse(rf);
    },
  },
};
</script>

<style scoped>
table {
  width: 300px;
}
th {
  width: 120px;
}
td {
  text-align: right;
}
table,
th,
td {
  border-spacing: 0;
  border: 1px solid #333;
}
td.description {
  padding: 3px;
  height: 70px;
  text-align: left;
  vertical-align: top;
}
.signature {
  display: flex;
  justify-content: center;
  text-align: center;
}

.signature span {
  width: 300px;
  border-top: 1px solid #000;
}
</style>
